@import './assets/sass/base/base.scss';
@import './assets/sass/base/variables.scss';
.app {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
