@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,500,600,800&display=swap);
* {
  margin: 0;
  padding: 0;
  border: 0;
  font-family: 'Raleway', sans-serif;
  outline: 0;
  font-display: swap; }

body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-x: hidden; }

a {
  text-decoration: none; }

li {
  list-style: none;
  padding: 0;
  border: 0;
  margin: 0; }

.app {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.loader {
  height: 60px;
  margin: auto;
  background: rgba(241, 242, 243, 0);
  display: block; }

